import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from "@ionic/storage";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private authService: AuthService,public router: Router, public translate: TranslateService, private storage: Storage) { 
    translate.addLangs(['uk', 'es', 'port', 'fr']);
  }

  async ionViewWillEnter() {
    /* get langue */
    this.translate.use('uk');
  }

  async logout() {
    await this.authService.logout();
    this.router.navigateByUrl('/', { replaceUrl: true });
  }

  async change_langue(){
    /* get langue */
    await this.storage.create();
    this.storage.get("langue").then((data) => {
      if(data){
        this.translate.use(data);
      }else{
        this.translate.use('uk');
      }
    });
  }
}
