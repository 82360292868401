export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB4EdQ_R_HUmfYdNWXSYe9LiW5oStD7bis",
    authDomain: "eyefirstapp.firebaseapp.com",
    projectId: "eyefirstapp",
    storageBucket: "eyefirstapp.appspot.com",
    messagingSenderId: "853120283052",
    appId: "1:853120283052:web:95ca9aa72b7d30a1408a09",
    measurementId: "G-R7R0650V9K"
  }
};