import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class DataService {
  data: any;

  /* local */
  // prefixUrl: string = "http://localhost/EYEAPP_local/src/";

  /* live */
   prefixUrl: string = ""; 

  constructor(
    private http: HttpClient
  ) { }

  /* ---------------- generer les maths ---------------- */
  add_data_patient(infos: any) {
    return this.http.post(this.prefixUrl + "assets/json/Common/add_data_patient.php/", infos).pipe(map((res: any) => res ));
  }

  save_video(infos: any) {  
    return this.http.post(this.prefixUrl + "assets/json/Common/save_video.php/", infos).pipe(map((res: any) => res ));
  }

  liste_patient() {  
    return this.http.get(this.prefixUrl + "assets/json/Common/liste_patient.php/").pipe(map((res: any) => res ));
  }

  get_all_questions(infos: any) {  
    return this.http.post(this.prefixUrl + "assets/json/Common/liste_questions.php/",infos).pipe(map((res: any) => res ));
  }

  get_all_cl_questions(infos: any) {  
    return this.http.post(this.prefixUrl + "assets/json/Common/liste_questions_cl.php/",infos).pipe(map((res: any) => res ));
  }

  save_answers(infos: any) {  
    return this.http.post(this.prefixUrl + "assets/json/Common/save_answers.php/", infos).pipe(map((res: any) => res ));
  }

  save_answers_cl(infos: any) {  
    return this.http.post(this.prefixUrl + "assets/json/Common/save_answers_cl.php/", infos).pipe(map((res: any) => res ));
  }
  
  add_aemet_data(infos: any) {
    return this.http.post(this.prefixUrl + "assets/json/Common/add_aemet_data.php/",infos).pipe(map((res: any) => res ));
  }

  get_oneanswer_questions(infos: any) {  
    return this.http.post(this.prefixUrl + "assets/json/Common/liste_oa_questions.php/",infos).pipe(map((res: any) => res ));
  }

  save_eyesize(infos: any) {  
    return this.http.post(this.prefixUrl + "assets/json/Common/save_eyesize.php/", infos).pipe(map((res: any) => res ));
  }

  save_patient(infos: any) {  
    return this.http.post(this.prefixUrl + "assets/json/Common/save_patient.php/", infos).pipe(map((res: any) => res ));
  }

}
