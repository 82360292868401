
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { redirectUnauthorizedTo, redirectLoggedInTo, canActivate, } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToVideo = () => redirectLoggedInTo(['langue']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule),...canActivate(redirectLoggedInToVideo),
  },
  {
    path: 'video',
    loadChildren: () => import('./video/video.module').then((m) => m.VideoPageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'instruction',
    loadChildren: () => import('./instruction/instruction.module').then(m => m.InstructionPageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'remerciement',    
    loadChildren: () => import('./remerciement/remerciement.module').then(m => m.RemerciementPageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'start-video',
    loadChildren: () => import('./start-video/start-video.module').then( m => m.StartVideoPageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'langue',
    loadChildren: () => import('./langue/langue.module').then( m => m.LanguePageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'questions',
    loadChildren: () => import('./questions/questions.module').then( m => m.QuestionsPageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'eyesize',
    loadChildren: () => import('./eyesize/eyesize.module').then( m => m.EyesizePageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'consent',
    loadChildren: () => import('./consent/consent.module').then( m => m.ConsentPageModule),
  },
  {
    path: 'patient-info',
    loadChildren: () => import('./patient-info/patient-info.module').then( m => m.PatientInfoPageModule),
  },
  {
    path: 'clquestions',
    loadChildren: () => import('./clquestions/clquestions.module').then( m => m.ClquestionsPageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'test-getapi',
    loadChildren: () => import('./test-getapi/test-getapi.module').then( m => m.TestGetapiPageModule),...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }